// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sheet-animation {
    position: absolute;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/sheetAnimation/sheetAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":[".sheet-animation {\n    position: absolute;\n    width: 400px;\n    height: 400px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
