// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container hr {
  width: 60%;
}

.hr-container {
  padding: 2%;
}

.container-img-logo {
  height: 15vmin;
  padding-top: 4%;
}

.validate-container {
  width: 70%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.section-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-title-fail {
  font-style: normal;
  font-weight: 500;
  color: #d13635;
}`, "",{"version":3,"sources":["webpack://./src/pages/error/errorPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n}\n\n.container hr {\n  width: 60%;\n}\n\n.hr-container {\n  padding: 2%;\n}\n\n.container-img-logo {\n  height: 15vmin;\n  padding-top: 4%;\n}\n\n.validate-container {\n  width: 70%;\n  min-height: 70vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n}\n\n.section-container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.main-title-fail {\n  font-style: normal;\n  font-weight: 500;\n  color: #d13635;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
