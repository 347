import Lottie from "lottie-react";
import { defaultOptions } from "../shared/lotieAnimationOptions";
import './sheetAnimation.css'

const SheetAnimation = () => {
  return (
    <Lottie className="sheet-animation"
      {...defaultOptions}
    />
  );
};
export default SheetAnimation;
