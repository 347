// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .container hr {
    width: 60%;
  }
  
  .hr-container {
    padding: 2%;
  }
  
  .container-img-logo {
    height: 15vmin;
    padding-top: 4%;
  }
  
  .validate-container {
    width: 70%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .section-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .main-title {
    font-style: normal;
    font-weight: 500;
    color: #4caf50;
  }
  
  .main-title-fail {
    font-style: normal;
    font-weight: 500;
    color: #d13635;
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .email {
    margin-right: 20px;
    height: 25px;
    vertical-align: middle;
  }
  
  .footer-container {
    display: flex;
    justify-content: center;
  }
  
  .info-span {
    padding-top: 5%;
  }
  
  .info-link {
    cursor: default;
    text-decoration: none;
    color: #2d4d82;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/response/response.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,cAAc;EAChB","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n  }\n  \n  .container hr {\n    width: 60%;\n  }\n  \n  .hr-container {\n    padding: 2%;\n  }\n  \n  .container-img-logo {\n    height: 15vmin;\n    padding-top: 4%;\n  }\n  \n  .validate-container {\n    width: 70%;\n    min-height: 70vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n  }\n  \n  .section-container {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n  }\n  \n  .main-title {\n    font-style: normal;\n    font-weight: 500;\n    color: #4caf50;\n  }\n  \n  .main-title-fail {\n    font-style: normal;\n    font-weight: 500;\n    color: #d13635;\n  }\n  \n  .info-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  \n  .email {\n    margin-right: 20px;\n    height: 25px;\n    vertical-align: middle;\n  }\n  \n  .footer-container {\n    display: flex;\n    justify-content: center;\n  }\n  \n  .info-span {\n    padding-top: 5%;\n  }\n  \n  .info-link {\n    cursor: default;\n    text-decoration: none;\n    color: #2d4d82;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
